import React, { Component } from 'react'
import Footer from "../footer/Footer"
import Nav from '../nav/Nav'
import './Contact.css'

class Contact extends Component {
    render() {
        return (
            <div className="contact_wrapper">
                <Nav />
                <div className="contact">
                    <p>
                        You can email me at:
                    </p>
                    <a href="mailto:hi@justinstals.com">hi@justinstals.com</a>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Contact
