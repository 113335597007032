import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import './SignIn.css'

class SignIn extends Component {
    state = {
        email: '',
        password: '',
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    }
  render() {
      const { authError, auth } = this.props
      if(auth.uid) return <Redirect to='/dashboard' />
    return (
      <div className="signin_container">
        <form onSubmit={this.handleSubmit}>
            <h5>Sign In</h5>
            <div className="input_field">
                <input type="email" id="email" placeholder="Email" onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <input type="password" id="password" placeholder="Password" onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <button>Login</button>
                <div>
                    <p>Don't have an account? Sign up <a href="/signup">here</a>.</p>
                </div>
            </div>
            <div className="red-text center">
                { authError ? <p>{authError}</p> : null}
            </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
