const initState = {}

const postReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_POST':
            console.log('Created post', action.post)
            return state;
        case 'CREATE_POST_ERROR':
            console.log('Created post error', action.err)
            return state;
        case 'EDIT_POST':
            console.log('Edited post', action.post)
            return state;
        case 'EDIT_POST_ERROR':
            console.log('Edit post error', action.err)
            return {
                ...state,
                postError: action.err.message
            }
        case 'DELETE_POST':
            console.log('Deleted post', action.post)
            return state;
        case 'DELETE_POST_ERROR':
            console.log('Delete post error', action.err)
            return {
                ...state,
                postError: action.err.message
            }
        default:
            return state;
    }
    
}

export default postReducer;