import React from 'react'
import PostSummary from './PostSummary'
import { Link } from 'react-router-dom'

const PostList = ({ posts, page, deletePost }) => {
    if(page === 'home') {
        return (
            <div className="home_posts">
                { posts && posts.map(post => {
                    return (
                        <PostSummary page={page} post={ post } key={post.id}/>
                    )
                })}
            </div>
        )
    } else if (page === 'dashboard') {
        return (
            <div className="dashboard_posts">
                { posts && posts.map(post => {
                    return (
                        <PostSummary page={page} post={ post } deletePost={deletePost} key={post.id}/>
                    )
                })}
            </div>
        )
    
    } else if (page === 'archive') {
        return (
            <div className="archive_posts">
                { posts && posts.map(post => {
                    return (
                        <PostSummary page={page} post={ post } key={post.id}/>
                    )
                })}
            </div>
        )
    } else {
        return (
            <div>
                { posts && posts.map(post => {
                    return (
                        <PostSummary page={page} post={ post } key={post.id}/>
                    )
                })}
            </div>
        )
    }
}

export default PostList;