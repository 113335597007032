import React from 'react'
import moment from 'moment'

const PostSummary = ({ post, page, deletePost }) => {
    if (page === 'home') {
        return(<a href={post.slug} className="home_post">
                <div className="home_post_image">
                    <div>
                        <div>
                            <img src={post.header_image} />
                        </div>
                    </div>
                </div>
                <div className="home_post_info">
                    {/* <div className="home_post_date">{moment(post.createdAt.toDate()).format('LL')}</div> */}
                    <h3 className="home_post_title">{ post.title }</h3>
                    {/* <span className="home_post_subtitle">{ post.subtitle }</span> */}
                </div>
            </a>)
    } else if (page === 'dashboard') {
        return (
            <div className="dashboard_post">
                <div className="dashboard_post_meta">
                    <div className="dashboard_post_id">{post.id}</div>
                    <h3 className="dashboard_post_title">{ post.title }</h3>
                    <span className="dashboard_post_subtitle">{ post.subtitle }</span>
                    <div className="dashboard_post_image">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="dashboard_post_date">{moment(post.createdAt.toDate()).calendar()}</div>
                </div>
                <div className="dashboard_post_links">
                    <a href={post.slug} className="dashboard_post_link" id="view_link">VIEW</a>
                    <a href={post.slug} className="dashboard_post_link" id="edit_link">EDIT</a>
                    <button type="button" className="dashboard_post_link" onClick={()=> deletePost(post)} id="delete_link">DELETE</button>
                </div>

                {/* <p className="home_post_author">{ post.authorFirstName } { post.authorLastName }</p> */}
                
            </div>
        )
    } else if (page === 'archive') {
        return (

            <a href={post.slug} key={post.id}>
                <div className="archive_post">
                    <div className="archive_post_meta">
                        <h3 className="archive_post_title">{ post.title }</h3>
                        <span className="archive_post_subtitle">{ post.subtitle }</span>
                        <div className="archive_post_date">{moment(post.createdAt.toDate()).calendar()}</div>
                    </div>
                </div>
            </a>
        )

    } else {
        return (
            <div className="card z-depth-0 archive">
                <a href={post.slug} key={post.id}>
                <div className="card-content grey-text text-darken-3">
                <span className="card-title">{ post.title }</span>
                
                <p>Posted by { post.authorFirstName } { post.authorLastName }</p>
                <div>{moment(post.createdAt.toDate()).calendar()}</div>
                </div>
                </a>
            </div>
        )
    }
}

export default PostSummary