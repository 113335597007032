const initState = {}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError: 'Login failed'
            }
        case 'LOGIN_SUCCESS':
            console.log('Login success.');
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Signout success.');
            return state;
        case 'SIGNUP_SUCCESS':
            console.log('Signup success.');
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('Signup error.');
            return {
                ...state,
                authError: action.err.message
            }
        case 'ADD_SUBSCRIBER':
            console.log('Subscriber added.');
            return {
                ...state,
                authError: null
            }
        case 'ADD_SUBSCRIBER_ERROR':
            console.log('Subscriber error.');
            return {
                ...state,
                authError: action.err.message
            }
        default:
            return state;
    }
}

export default authReducer