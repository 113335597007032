import React from 'react';
import './SignOut.css';
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const SignOutButton = (props) => (
  <button type="button" onClick={props.signOut} className="signout_btn">
    <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <path d="M5.12132034,1.58578644 C5.90236893,0.804737854 7.16869888,0.804737854 7.94974747,1.58578644 L7.94974747,1.58578644 L15.728,9.364 L23.5060967,1.58578644 C24.2871452,0.804737854 25.5534752,0.804737854 26.3345238,1.58578644 L29.8700577,5.12132034 C30.6511063,5.90236893 30.6511063,7.16869888 29.8700577,7.94974747 L22.092,15.728 L29.8700577,23.5060967 C30.6099984,24.2460374 30.6489427,25.4215216 29.9868904,26.207309 L29.8700577,26.3345238 L26.3345238,29.8700577 C25.5534752,30.6511063 24.2871452,30.6511063 23.5060967,29.8700577 L23.5060967,29.8700577 L15.728,22.092 L7.94974747,29.8700577 C7.16869888,30.6511063 5.90236893,30.6511063 5.12132034,29.8700577 L1.58578644,26.3345238 C0.804737854,25.5534752 0.804737854,24.2871452 1.58578644,23.5060967 L9.364,15.728 L1.58578644,7.94974747 C0.845845674,7.2098067 0.806901424,6.03432255 1.46895369,5.24853511 L1.58578644,5.12132034 Z" id="Combined-Shape" stroke="#000000" fill="#EC5454" />
        </g>
      </svg>
  </button>
);

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignOutButton);