import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import App from '../../App';
import { connect } from 'react-redux'
import { createPost } from '../../store/actions/postActions'
import './CreatePost.css'

class CreatePost extends Component {
    
    state = {
        title: '',
        body: '',
        slug: '',
        subtitle: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleBodyChange = (e) => {
        this.setState({
            body: e
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createPost(this.state);
        this.props.history.push('/');
    }
  render() {
    return (
      <div className="create_post">
        <form  onSubmit={this.handleSubmit}>
            <h5>Create Post</h5>
            
            <div className="input_field">
                <input type="text" id="title" placeholder="Title" onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <input type="text" id="subtitle" placeholder="Subtitle" onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <input type="text" id="slug" placeholder="Slug" onChange={this.handleChange}></input>
            </div>
            <div>
                <ReactQuill modules={App.modules} onChange={this.handleBodyChange}></ReactQuill>
            </div>

            <div className="input_field">
                <button>Create</button>
            </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createPost: (post) => dispatch(createPost(post))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)
