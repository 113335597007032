import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var config = {
    // apiKey: "AIzaSyA4S8EpV8gozORXDoINsb15NxHNddXAXTc",
    // authDomain: "sifiblog.firebaseapp.com",
    // databaseURL: "https://sifiblog.firebaseio.com",
    // projectId: "sifiblog",
    // storageBucket: "sifiblog.appspot.com",
    // messagingSenderId: "829714278874"
    apiKey: "AIzaSyCmM80S7cJgDQ1JKv5lPApJKh42eXlzHeg",
    authDomain: "justinstalsart.firebaseapp.com",
    databaseURL: "https://justinstalsart.firebaseio.com",
    projectId: "justinstalsart",
    storageBucket: "justinstalsart.appspot.com",
    messagingSenderId: "66831179756",
    appId: "1:66831179756:web:1aa24bb28e13f487a3eac9",
    measurementId: "G-K0JHMPFWFF"
};

firebase.initializeApp(config)
firebase.firestore()

export default firebase;