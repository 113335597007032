import React, { Component } from 'react'
import './About.css'
import Nav from '../nav/Nav'
import Footer from "../footer/Footer"
import { NavLink } from 'react-router-dom'
// import meditate from './Meditate.gif'
import intro from './Intro.png'

class About extends Component {
    render() {
        return (
            <div className="about_wrapper">
                <Nav />
                <div className="about">
                    <img
                    alt="I'm gonna call him Dante"
                    src={intro}/>
                    {/* <img
                    alt="The creative force is strong with this one"
                    src={meditate}/> */}
                    <p>
                        Hi, I'm Justin. 
                        <br />
                        <br />
                        I'm a designer and software engineer from Sydney with a special love for illustration and animation.
                        <br />
                        <br />
                        By day, I do design and web development for a range of clients. You can check out some highlights of my other work on my main <a href="https://www.justinstals.com" rel="noopener noreferrer" target="_blank">portfolio site</a>.
                        <br />
                        <br />
                        Before starting on my own, I helped the team at <a href="https://www.hellostake.com" rel="noopener noreferrer" target="_blank">Stake</a> build a stock brokerage, and the team at <a href="https://www.airtree.vc" rel="noopener noreferrer" target="_blank">AirTree Ventures</a> find the best founders in Australia and New Zealand.
                        <br />
                        <br />
                        If you're a reader, check out my (very infrequent) <a href="https://sifiblog.com" rel="noopener noreferrer" target="_blank">blogs</a>.
                        <br />
                        <br />
                        If you'd like to discuss working together, <NavLink to="/contact">let me know</NavLink>.
                        
                    </p>
                </div>
                <Footer />
            </div>
        )
    }
}

export default About
