import React, { Component } from 'react'
// import Loader from './Loader.gif'

const Spinner = (props) => {
    return (
        // <img src={Loader} alt="Loading..." />
        <svg className="lds-dna" width="199px" height="199px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background: 'none'}}><circle cx="5.405405405405405" cy="38.9108" r="2.6689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-0.55s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="0s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.55s" />
      </circle><circle cx="5.405405405405405" cy="61.0892" r="3.3311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.55s" />
      </circle><circle cx="13.513513513513514" cy="35.5162" r="2.8689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-0.7333333333333334s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-0.18333333333333335s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.7333333333333334s" />
      </circle><circle cx="13.513513513513514" cy="64.4838" r="3.1311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333335s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.2833333333333334s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.7333333333333334s" />
      </circle><circle cx="21.62162162162162" cy="35.1368" r="3.0689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-0.9166666666666666s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-0.3666666666666667s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.9166666666666666s" />
      </circle><circle cx="21.62162162162162" cy="64.8632" r="2.9311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.0166666666666666s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.4666666666666668s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-0.9166666666666666s" />
      </circle><circle cx="29.729729729729733" cy="37.436" r="3.2689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.1s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-0.55s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.1s" />
      </circle><circle cx="29.729729729729733" cy="62.564" r="2.7311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.2s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.1s" />
      </circle><circle cx="37.83783783783784" cy="43.7674" r="3.4689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.2833333333333332s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-0.7333333333333334s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.2833333333333332s" />
      </circle><circle cx="37.83783783783784" cy="56.2326" r="2.5311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.3833333333333333s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333333s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.2833333333333332s" />
      </circle><circle cx="45.94594594594595" cy="53.3962" r="3.5311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.466666666666667s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-0.9166666666666667s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.466666666666667s" />
      </circle><circle cx="45.94594594594595" cy="46.6038" r="2.4689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.566666666666667s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.016666666666667s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.466666666666667s" />
      </circle><circle cx="54.054054054054056" cy="61.0892" r="3.3311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" />
      </circle><circle cx="54.054054054054056" cy="38.9108" r="2.6689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.75s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" />
      </circle><circle cx="62.16216216216216" cy="64.4838" r="3.1311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333335s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.2833333333333334s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333335s" />
      </circle><circle cx="62.16216216216216" cy="35.5162" r="2.8689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.933333333333334s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.3833333333333337s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333335s" />
      </circle><circle cx="70.27027027027027" cy="64.8632" r="2.9311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.0166666666666666s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.4666666666666668s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.0166666666666666s" />
      </circle><circle cx="70.27027027027027" cy="35.1368" r="3.0689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-3.1166666666666667s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.5666666666666664s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.0166666666666666s" />
      </circle><circle cx="78.37837837837839" cy="62.564" r="2.7311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.2s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.6500000000000001s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.2s" />
      </circle><circle cx="78.37837837837839" cy="37.436" r="3.2689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-3.3000000000000003s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.75s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.2s" />
      </circle><circle cx="86.4864864864865" cy="56.2326" r="2.5311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.3833333333333337s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-1.8333333333333335s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.3833333333333337s" />
      </circle><circle cx="86.4864864864865" cy="43.7674" r="3.4689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-3.483333333333334s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.933333333333334s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.3833333333333337s" />
      </circle><circle cx="94.5945945945946" cy="46.6038" r="2.4689">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-2.5666666666666664s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-2.0166666666666666s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.5666666666666664s" />
      </circle><circle cx="94.5945945945946" cy="53.3962" r="3.5311">
        <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2.2s" repeatCount="indefinite" begin="-3.6666666666666665s" />
        <animate attributeName="cy" keyTimes="0;0.5;1" values="35;65;35" dur="2.2s" repeatCount="indefinite" begin="-3.1166666666666667s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
        <animate attributeName="fill" keyTimes="0;0.5;1" values="#6d5fc6;#c9c2e1;#6d5fc6" dur="2.2s" repeatCount="indefinite" begin="-2.5666666666666664s" />
      </circle></svg>
    )
}

export default Spinner
