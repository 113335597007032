import React from 'react'
import moment from 'moment';

const Subscribers = (props) => {
    const { subscribers } = props;
    return (
        <ul className="subscribers">
            { subscribers && subscribers.map(item => {
                return (
                    <li key={item.id}>
                        <span>{item.email}</span>
                        <div className="date">
                            { moment(item.joinedAt.toDate()).fromNow()}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default Subscribers