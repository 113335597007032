import React, { Component } from 'react'
import './Footer.css'


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <footer>
                <button className="to_top" onClick={() => this.scrollToTop()}>
                    <svg width="11px" height="17px" viewBox="0 0 11 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="Desktop-HD" transform="translate(-594.000000, -2547.000000)" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth={2}>
                            <polygon id="Path-148" points="599.624957 2563 599.538639 2548 595 2555.53316 604 2555.53316 599.624957 2548" />
                        </g>
                        </g>
                    </svg>
                </button>
                <p>© 2020 Justin Stals. All rights reserved.</p>  
            </footer>
        )
    }
}

export default Footer