export const createPost = (post) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('posts').add({ 
            ...post,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            author_id: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_POST', post });
        }).catch((err) => {
            dispatch({ type: 'CREATE_POST_ERROR', err})
        })
    }
};

export const editPost = (post) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('posts').doc(post.id).update({
            body: post.body,
            title: post.title,
            subtitle: post.subtitle,
            published: post.published,
            slug: post.slug,
            editedAt: new Date()
        }).then(() => {
            dispatch({ type: 'EDIT_POST', post });
        }).catch((err) => {
            dispatch({ type: 'EDIT_POST_ERROR', err})
        })
    }
};

export const deletePost = (post) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('posts').doc(post.id).delete().then(() => {
            dispatch({ type: 'DELETE_POST'});
        }).catch((err) => {
            dispatch({ type: 'DELETE_POST_ERROR', err})
        })
    }
};
