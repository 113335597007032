import React from 'react'
import './Post.css'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import renderHTML from 'react-render-html'
import { compose } from 'redux'
import moment from 'moment'
import Spinner from '../layout/Spinner'
import ReactGA from 'react-ga';
import {InlineShareButtons} from 'sharethis-reactjs';
import Parser from 'html-react-parser';
import Nav from '../nav/Nav';

ReactGA.initialize('UA-137389913-1', {
    debug: true,
    titleCase: false,
  });

const Post = (props) => {
    const { post } = props;
    
    if (post) {
        ReactGA.pageview('post/' + post.slug);
        return (
            <div>
                <Nav />
            
            <div className="post_container">
                
                <table className="post_table">
                    <tr className="header_image_row">
                        <td>
                            <img src={post.header_image} />
                        </td>
                    </tr>
                    { post.gallery && post.gallery.map((picture, index) => {
                        return (
                            <tr className="gallery_image_row">
                                <td>
                                    <img src={picture} />
                                </td>
                            </tr>
                        )
                    })}
                    {/* <tr className="meta_row">
                        <td>
                            <h1 className="title">{ post.title }</h1>
                            <h3 className="subtitle">{ post.subtitle }</h3>
                            <span className="date">{moment(post.createdAt.toDate()).format('LL')}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="body">
                                {post.body && Parser(post.body)}
                            </div>
                        </td>
                    </tr> */}
                    <tr>
                        <td>
                            <div className="share_section">
                                <h3>Share This Post</h3>
                                <InlineShareButtons
                                    config={{
                                        alignment: 'center',  // alignment of buttons (left, center, right)
                                        color: 'white',      // set the color of buttons (social, white)
                                        enabled: true,        // show/hide buttons (true, false)
                                        font_size: 16,        // font size for the buttons
                                        labels: 'null',        // button labels (cta, counts, null)
                                        language: 'en',       // which language to use (see LANGUAGES)
                                        networks: [           // which networks to include (see SHARING NETWORKS)
                                        'email',
                                        'facebook',
                                        'messenger',
                                        'linkedin',
                                        'twitter',
                                        'whatsapp',
                                        'reddit',
                                        ],
                                        padding: 0,          // padding within buttons (INTEGER)
                                        radius: 25,            // the corner radius on each button (INTEGER)
                                        show_total: false,
                                        show_mobile: true,
                                        size: 30,             // the size of each button (INTEGER)

                                        // OPTIONAL PARAMETERS
                                        url: `https://justinstals.art/${post.slug}`,
                                        description: `Art by Justin Stals: ${post.title}`,       // (defaults to og:description or twitter:description)
                                        title: `Art by Justin Stals: ${post.title}`,            // (defaults to og:title or twitter:title)
                                        message: `Check this out: https://justinstals.art/${post.slug}`,     // (only for email sharing)
                                        subject: `Art by Justin Stals: ${post.title}`,  // (only for email sharing)
                                        username: 'JustinStals' // (only for twitter sharing)
                                    }}
                                />
                                <p>
                                    © 2020 Justin Stals. All rights reserved.
                                </p>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            </div>
        )
    } else {
        return (
            <div className="loading">
                <Spinner />
            </div>
        )
    }
    
}

const mapStateToProps = (state, ownProps) => {
    
    const slug = ownProps.match.params.slug;
    const posts = state.firestore.ordered.posts;
    const post = posts ? posts.find((post) => { return post.slug === slug}) : null
    
    return {
        post: post
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'posts' }
    ])
)(Post)
