import React, { Component } from 'react'
import Notifications from '../dashboard/Notifications'
import PostList from '../posts/PostList'
import Spinner from '../layout/Spinner'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import './Home.css'
import ReactGA from 'react-ga'
// import Subscribe from '../subscribe/Subscribe'
import Footer from '../footer/Footer'
import Nav from '../nav/Nav'

ReactGA.initialize('UA-137389913-1', {
    debug: true,
    titleCase: false,
  });

class Home extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        ReactGA.pageview('/home');
        const { posts, auth } = this.props;
        if (posts) {
            return (
                <div className="home_wrapper">
                    <Nav />
                    <div className="posts_wrapper">
                        <PostList page="home" posts={ posts }/>
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return (
                <div className="loading">
                    <Spinner />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        posts: state.firestore.ordered.posts,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'posts', limit: 7, orderBy: ['createdAt', 'desc'] }
    ])
)(Home)