import React from 'react'
import moment from 'moment';

const Notifications = (props) => {
    const { notifications } = props;
    return (
        <ul className="notifications">
            { notifications && notifications.map(item => {
                return (
                    <li key={item.id}>
                        <span>{item.user}</span> <span>{item.content}</span>
                        <div className="date">
                            { moment(item.time.toDate()).fromNow()}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default Notifications