import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import App from '../../App';
import { connect } from 'react-redux'
import { editPost } from '../../store/actions/postActions'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import './EditPost.css'

class EditPost extends Component {
    
    state = {
        post_fetched: false,
        post: null,
        id: '',
        title: '',
        subtitle: '',
        body: '',
        slug: '',
        published: false
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
        
    }
    handleBodyChange = (e) => {
        this.setState({
            body: e
        })
    }
    handlePublishChange = (e) => {
        this.setState({
            published: !this.state.published
        }, () => console.log(this.state.published));
    }
    getPost = (id, posts) => {
        var post = posts.find((post) => post.id === id)
        if (post) {
            this.setState({
                post: post,
                post_fetched: true
            }, () => this.fillData());
        }
    }

    fillData = () => {
        this.setState({
            title: this.state.post.title,
            body: this.state.post.body,
            slug: this.state.post.slug,
            subtitle: this.state.post.subtitle,
            published: this.state.post.published
        });
    }

    populatePost = () => {
        this.setState({})
        if (this.state.id && !this.state.post_fetched) {
            this.getPost(this.state.id, this.props.posts)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.editPost(this.state);
        this.props.history.push('/');
    }

  render() {
      const id_present = this.state.id;
    return (
      <div className="edit_post">
        <form onSubmit={this.handleSubmit}>
            <h5>Edit Post</h5>
            <div className="input_field">
                <input type="text" id="id" placeholder="Post ID" onChange={this.handleChange}></input>
                { id_present ? <button type="button" className="btn lighten-1 z-depth-0" onClick={this.populatePost}>Populate</button> : null }
            </div>
            <div className="input_field">
                <input type="text" id="title" placeholder="Title" value={this.state.title} onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <input type="text" id="subtitle" placeholder="Subtitle" value={this.state.subtitle} onChange={this.handleChange}></input>
            </div>
            <div className="input_field">
                <input type="text" id="slug" placeholder="slug" value={this.state.slug} onChange={this.handleChange}></input>
            </div>
            <div>
                <ReactQuill modules={App.modules} value={this.state.body} onChange={this.handleBodyChange}></ReactQuill>
            </div>
            <div className="input-checkbox">
                <input type="checkbox" id="published" defaultChecked={this.state.published} checked={this.state.published} onChange={this.handlePublishChange} className="dashboard_checkbox"/>
                { this.state.published ? <p id="published">Published</p> : <p id="published">Publish?</p> }
            </div>
            <div className="input_field">
                <button>Update</button>
            </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        posts: state.firestore.ordered.posts,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editPost: (post) => dispatch(editPost(post))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'posts' }
    ])
)(EditPost)