import React, { Component } from 'react'
import Notifications from './Notifications'
import Subscribers from './Subscribers'
import PostList from '../posts/PostList'
import CreatePost from '../posts/CreatePost'
import EditPost from '../posts/EditPost'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect, NavLink } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "react-tabs/style/react-tabs.css";
import { deletePost } from '../../store/actions/postActions'
import './Dashboard.css'
import { min } from 'moment';
import SignOutButton from "../auth/SignOut"

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          postToDelete: null
        };
    }

    deletePost = (post) => {
        this.setState({
            postToDelete: post
        })
    }
    cancelDeletePost = () => {
        this.setState({
            postToDelete: null
        })
    }
    confirmDeletePost = () => {
        this.props.deletePost(this.state.postToDelete);
        this.setState({
            postToDelete: null
        })
    }

    render() {
        const { posts, auth, notifications, subscribers } = this.props;
        const postToDelete = this.state.postToDelete;
        if(!auth.uid) return <Redirect to='/signin' />
        return (
            <div className="dashboard_container">
                <div className="nav_links">
                    <div className="link_home">
                        <NavLink to="/">
                            <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <g id="Group-18" transform="translate(1.000000, 1.000000)" stroke="#000000">
                                    <path d="M24,0 L26,0 C27.1045695,-2.02906125e-16 28,0.8954305 28,2 L28,10 L28,10 L22,10 L22,2 C22,0.8954305 22.8954305,2.02906125e-16 24,0 Z" id="Rectangle" fill="#FFF9F3" />
                                    <path d="M14,4 C16.5134167,4 21.1800834,6.33815186 28,11.0144556 L28,24 C28,26.209139 26.209139,28 24,28 L4,28 C1.790861,28 2.23265109e-15,26.209139 0,24 L0,10.6256505 L0,10.6256505 C6.81991661,6.20855017 11.4865833,4 14,4 Z" id="Rectangle" fill="#FFF9F3" />
                                    <rect id="Rectangle" fill="#D6F1FF" x={4} y={17} width={7} height={7} rx={2} />
                                    <path d="M2.02922007,5.85448011 C7.74521954,2.61816004 11.7354795,1 14,1 C16.2645215,1 20.2547839,2.61816144 25.9707874,5.85448433 C27.2248258,6.56450701 28,7.89419857 28,9.33529004 L28,12 L28,12 C21.1871874,7.8004824 16.5205207,5.7007236 14,5.7007236 C11.4794793,5.7007236 6.81281263,7.8004824 0,12 L0,9.33527829 C8.81241045e-06,7.89418886 0.775181945,6.5644992 2.02922007,5.85448011 Z" id="Rectangle" fill="#B7ACA3" />
                                    <path d="M19,17 C21.209139,17 23,18.790861 23,21 L23,28 L23,28 L15,28 L15,21 C15,18.790861 16.790861,17 19,17 Z" id="Rectangle" fill="#B7ACA3" />
                                </g>
                                </g>
                            </svg>   
                        </NavLink>
                    </div>
                    <div className="link_signout">
                        <SignOutButton />
                    </div>
                </div>
                <Tabs>
                    <TabList>
                        <Tab>New Post</Tab>
                        <Tab>All Posts <span className="tab_number">{ posts && posts.length}</span></Tab>
                        <Tab>Edit Post</Tab>
                        <Tab>Notifications <span className="tab_number">{ notifications && notifications.length}</span></Tab>
                        <Tab>Subscribers <span className="tab_number">{ subscribers && subscribers.length}</span></Tab>
                    </TabList>
                    <TabPanel>
                        <div className="create_post_wrapper">
                            <CreatePost history={ this.props.history } />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        { postToDelete ?
                            <div className="delete_post_popup">
                                <p>Are you sure you want to delete <span>{postToDelete.title}?</span></p>
                                <button type="button" className="delete_post_btn yes" onClick={this.confirmDeletePost}>YES</button>
                                <button type="button" className="delete_post_btn no" onClick={this.cancelDeletePost}>NO</button>
                            </div>
                            : null 
                        }
                        <div className="posts_wrapper">
                            <PostList posts={ posts } page="dashboard" deletePost={this.deletePost} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="edit_post_wrapper">
                            <EditPost history={ this.props.history } />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="notifications_wrapper">
                            <Notifications notifications={ notifications } />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="subscribers_wrapper">
                            <Subscribers subscribers={ subscribers } />
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        posts: state.firestore.ordered.posts,
        auth: state.firebase.auth,
        notifications: state.firestore.ordered.notifications,
        subscribers: state.firestore.ordered.subscribers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deletePost: (post) => dispatch(deletePost(post))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'posts', orderBy: ['createdAt', 'desc'] },
        { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
        { collection: 'subscribers', orderBy: ['joinedAt', 'desc'] }
    ])
)(Dashboard)