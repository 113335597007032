import React, { Component } from 'react';
import './App.css';
import { Switch, BrowserRouter, Route } from "react-router-dom";
import * as ROUTES from './constants/routes'
import Home from "./components/home/Home";
import Dashboard from "./components/dashboard/Dashboard";
import Post from "./components/posts/Post";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import Error from "./components/Error/Error";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";

class App extends Component {

  render() {

    return (
        <BrowserRouter>
            <div>
            <Switch>
                <Route exact path={ROUTES.HOME} component={ Home }  />
                <Route path={ROUTES.DASHBOARD} component={ Dashboard }  />
                <Route path={ROUTES.ABOUT} component={ About }  />
                <Route path={ROUTES.CONTACT} component={ Contact }  />
                {/* <Route path={ROUTES.SIGN_UP} component={ SignUp } /> */}
                <Route path={ROUTES.SIGN_IN} component={ SignIn } />
                <Route path={ROUTES.POST} component={ Post } ref={this.myRef} />
                <Route component={ Error } />
            </Switch>
            </div>
        </BrowserRouter>
    );
  }
}

App.modules = {
  toolbar: [
      [{header: '1'}, {header: '2'}, {font: []}],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['link', 'image', 'video'],
      ['clean'],
      ['code-block']
    ]
};

App.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link', 'image', 'video', 'code-block'
]

export default App;
